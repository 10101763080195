import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

export default function PageSEO({ title }) {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata.title
  const fullTitle = title ? `${title} - ${siteTitle}` : siteTitle

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>{fullTitle}</title>
      <meta
        name="description"
        content="A center for resources and information relating to the Genevan Psalter, including articles, sheet music, recordings, books, websites and metrical translations based on it."
      />
    </Helmet>
  )
}
